html.prevent-scroll, body.prevent-scroll {
    overflow: hidden !important;
}

.container {
    width: 100% ;
    margin-right: auto;
    margin-left: auto;
}

.btn-facebook {
    background-color: #055EEE !important;
    color: white !important;
}

.btn-google {
    box-shadow: rgba(123, 123, 123, 0.24) 0px 2px 7px 0px, rgba(123, 123, 123, 0.24) -1px 0px 8px 0px;
    color: rgba(0, 0, 0, 0.54) !important;
    background-color: white !important;
}

@font-face {
    font-family: 'Avenir 85';
    src: url('../fonts/Avenir-Heavy.eot');
    src: url('../fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Avenir-Heavy.woff2') format('woff2'),
    url('../fonts/Avenir-Heavy.woff') format('woff'),
    url('../fonts/Avenir-Heavy.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT 35';
    src: url('../fonts/AvenirLT-Light.woff2') format('woff2'),
    url('../fonts/AvenirLT-Light.woff') format('woff'),
    url('../fonts/AvenirLT-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir-Book';
    src: url('../fonts/Avenir-Book.woff2') format('woff2'),
    url('../fonts/Avenir-Book.woff') format('woff'),
    url('../fonts/Avenir-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir-Medium';
    src: url('../fonts/Avenir-Medium.woff2') format('woff2'),
    url('../fonts/Avenir-Medium.woff') format('woff'),
    url('../fonts/Avenir-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    overflow: auto;
}

p, h1, h2, h3, h4, h5, h6, ul, li {
    padding: 0;
    margin: 0;
}
button a:hover {
    text-decoration: none;
}
button:focus {
    box-shadow: none;
}
.btn:focus {
    box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75) !important;
}
.btn-danger:not(:disabled):not(.disabled):active {
    background-color: #cc0605 !important;
}
.btn-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: none !important;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none !important;
}
.clr {
    clear: both;
}
@keyframes swing {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(10deg);
    }
    30% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    60% {
        transform: rotate(5deg);
    }
    70% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes sonar {
    0% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}
.btn-danger:not(:disabled):not(.disabled):active {
    background-color: transparent !important;
    border: 0 !important;
    outline: 0 !important;
}
/********************* Header Start *****************/
.wrapper-container {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
}
.wrapper-container .row {
    margin: 0;
}
header .bg-white {
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 12px -5px rgba(150, 150, 150, 0.75);
    -moz-box-shadow: 0px 2px 12px -5px rgba(150, 150, 150, 0.75);
    box-shadow: 0px 2px 12px -5px rgba(150, 150, 150, 0.75);
    padding: 20px;
}
header .company-profile {
    padding: 20px;
    -webkit-box-shadow: 0px 6px 29px -2px rgba(212, 212, 212, 0.75) !important;
    -moz-box-shadow: 0px 6px 29px -2px rgba(212, 212, 212, 0.75) !important;
    box-shadow: 0px 6px 29px -2px rgba(212, 212, 212, 0.75) !important;
}
header .bg-white .site-logo img {
    height: 65.32px;
    width:  65.66px;
    object-fit: contain;
}
/*header.map-bg .overlay::before {
	background: rgba(255, 255, 255, 0.6) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}*/
header.map-bg {
    background-image: url('../../assets/images/component.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /*background-size: 1062px;*/
}
header.map-bg::before {
    background: rgba(255, 255, 255, 0.6) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
header .humburger-img img {
    height: 28.94px;
    width: 35.94px;
    object-fit: contain;
}
header.map-bg .header-signup-btn button {
    background-color: #cc0605;
    padding: 6px 18px;
    font-size: 22px;
    border: 0;
    margin-top: -5px;
}
header.map-bg .header-signup-btn button a {
    color: #fff;
}
header .map-button button {
    position: absolute;
    top: 90px;
    margin-left: 84px;
    background-color: #fff;
    border-radius: 50px;
    padding: 12px 36px;
    font-size: 14px;
    -webkit-box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75);
}
header .map-button button:focus {
    box-shadow: none;
    -webkit-box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75);
}
header .map-icon img,
header .map-icon2 img,
header .map-icon3 img,
header .map-icon4 img,
header .map-icon5 img,
header .map-icon6 img {
    width: 35.54px;
    height: 35.54px;
    object-fit: contain;
    position: absolute;
    top: 219px;
    margin-left: -33px;
}
header .map-icon2 img {
    margin-left: 150px;
    right: 116px;
}
header .map-icon3 img {
    top: 159px;
    margin-left: -113px !important;
}
header .map-icon4 img {
    top: 345px;
    margin-left: 70px !important;
}
header .map-icon5 img {
    margin-left: 154px !important;
    top: 197px;
}
header .map-icon6 img {
    top: 87px;
    margin-left: 122px !important;
}
header .page-wrapper .sidebar-wrapper,
header .sidebar-wrapper .sidebar-brand > a,
header .sidebar-wrapper .sidebar-dropdown > a:after,
header .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
header .sidebar-wrapper ul li a i,
header .page-wrapper .page-content,
header .sidebar-wrapper .sidebar-search input.search-menu,
header .sidebar-wrapper .sidebar-search .input-group-text,
header .sidebar-wrapper .sidebar-menu ul li a,
header #show-sidebar,
header #close-sidebar {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #fff;
}
/*----------------page-wrapper----------------*/
header .page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
}
header .page-wrapper .theme.chiller-theme {
    background: #1e2229;
}
/*----------------toggeled sidebar----------------*/
header .page-wrapper.toggled .sidebar-wrapper {
    left: 0px;
}

@media screen and (min-width: 768px) {
    .page-wrapper.toggled .page-content {
        padding-left: 300px;
    }
}
/*----------------show sidebar button----------------*/
header #show-sidebar {
    transition-delay: 0.3s;
    background-color: #fff;
    border: 0;
}
header #show-sidebar:focus {
    box-shadow: none;
}
/*----------------sidebar-wrapper----------------*/
header .sidebar-wrapper {
    width: 425px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -450px;
    z-index: 999;
}
header .sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
header .sidebar-wrapper a {
    text-decoration: none;
}
header .sidebar-content {
    padding: 40px 55px;
}
/*--------------------sidebar-brand----------------------*/
header .sidebar-wrapper .sidebar-brand > a {
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
}
header .sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
}
/*--------------------sidebar-header----------------------*/
.sidebar-content .sidebar-header {
    min-height: 50px;
}
header .sidebar-wrapper .sidebar-header {
    padding: 25px;
    overflow: hidden;
}
header .sidebar-wrapper .sidebar-header .user-pic {
    text-align: center;
}
header .sidebar-wrapper .sidebar-header .user-pic img {
    object-fit: cover;
    height: 66px;
    width: 66px;
}

header .sidebar-wrapper .sidebar-header .user-info {
    width: 100%;
    text-align: center;
}
header .sidebar-wrapper .sidebar-header .user-name {
    color: #fff;
    font-size: 20px;
}
header .sidebar-wrapper .sidebar-header .user-info .vip-btn {
    font-size: 18px;
    background-color: #662DFF;
    border: 0;
    padding: 7px 15px;
}
.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #662DFF !important;
    border-color: #662DFF !important;
    box-shadow: 0;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none !important;
}
header .sidebar-wrapper .sidebar-header .user-info .vip-btn:focus {
    outline: 0;
}
header .sidebar-wrapper .sidebar-header .user-info i {
    margin-right: 4px;
}
/*----------------------sidebar-menu-------------------------*/
header .sidebar-wrapper .sidebar-menu {
    padding-bottom: 10px;
}
header .sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: bold;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block;
}
header .sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 7px 0px 2px 20px;
}
header .sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 10px;
    font-size: 12px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 4px;
}
header .sidebar-wrapper .sidebar-menu ul li a i.fa-sticky-note {
    background-color: #FF3B30 !important;
}
header .sidebar-wrapper .sidebar-menu ul li a i.fa-credit-card {
    background-color: #5AC8FA !important;
}
header .sidebar-wrapper .sidebar-menu ul li a i.fa-globe {
    background-color: #5856D6 !important;
}
header .sidebar-wrapper .sidebar-menu ul li a i.fa-dollar-sign {
    background-color: #FF9500 !important;
}
header .sidebar-wrapper .sidebar-menu ul li a i.fa-question {
    background-color: #000000 !important;
}
header .sidebar-wrapper .sidebar-menu ul li a i.fa-user-shield {
    background-color: #4CD964 !important;
}
header .sidebar-wrapper .sidebar-menu ul li a i.fa-headphones-alt {
    background-color: #FFCC00 !important;
}
header .sidebar-wrapper .sidebar-menu ul li a i.fa-info-circle {
    background-color: #007AFF !important;
}
header .sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
    display: inline-block;
    animation: swing ease-in-out 0.5s 1 alternate;
}
header .sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
    font-style: normal;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    position: absolute;
    right: 15px;
    top: 14px;
}
header .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 5px 0;
}
header .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    padding-left: 25px;
    font-size: 13px;
}
header .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
    content: "\f111";
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    font-size: 8px;
}
header .sidebar-wrapper .sidebar-menu ul li a span.label,
header .sidebar-wrapper .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
}
header .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
header .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
}
header .sidebar-wrapper .sidebar-menu .sidebar-submenu {
    display: none;
}
header .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
    transform: rotate(90deg);
    right: 17px;
}
header .chiller-theme .sidebar-wrapper {
    background: #cc0605;
    display: none;
}
header .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
header .chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}
header .chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
header .chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
header .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
header .chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
header .chiller-theme .sidebar-wrapper .sidebar-brand>a,
header .chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
header .chiller-theme .sidebar-footer>a {
    color: #fff !important;
    font-size: 20px;
}
header .chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
header .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
header .chiller-theme .sidebar-wrapper .sidebar-header .user-info,
header .chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
header .chiller-theme .sidebar-footer>a:hover i {
    color: #b8bfce;
}
header .page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #fff;
    position: absolute;
    top: 40px;
    font-size: 30px;
    right: 37px;
}
header .page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}
header .chiller-theme .sidebar-wrapper ul li:hover a i,
header .chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
header .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
header .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: #16c7ff;
    text-shadow:0px 0px 10px rgba(22, 199, 255, 0.5);
}
header .chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
header .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
header .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
header .chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #BF30FF;
    color: #ffff !important;
}
header .chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
}
header .chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}
header .chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
}
/********************* Header End *****************/

/********************* Sign Up Page End *****************/
/********************* Send Message start *****************/
section.meassage-page textarea {
    width: 100%;
    border: 2px solid rgba(224, 224, 224, 0.5);
    font-family: 'Avenir 85';
    color: #495057;
}
section.meassage-page .signup-form form {
    width: 34% !important;
    margin: auto !important;
    background-color: #fff;
    padding: 28px 29px;
    margin-top: 6px !important;
    margin-bottom: 5px !important;
    /*box-shadow: rgba(227, 227, 227, 0.75) 0px 6px 29px -2px;*/
}
section.meassage-page .signup-form input {
    background-color: #fff;
    /*width: 60%;*/
}
section.meassage-page textarea {
    background-color: #fff;
}


.tablee .tablee_cell
{
    display: table-cell;
}
.tablee
{
    display: table;
    width:100%;
}
.map-container .map {
    max-height:730px;
    margin-right: 15px;
    position: relative;
    width: 100%;
    height: 730px;
    float: left;
    height: 100px;
    margin-top: 4px;

}
.map-container .map>div {
    height: 545px !important;
}
.map-container .map::before {
    /*background: rgba(255, 255, 255, 0.6) none repeat scroll 0 0;*/
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
img.menu_icon {
    width: 22.94px;
    height: 20.28px;
    vertical-align: bottom;
}


/***************** Aside Section Start********************/
.modal-bg.modal-header {
    height: 0!important;
    border-bottom: none!important;

    border-radius: 0;
}
.profile-popup .my-3 .row  .modal-img  {
    flex-shrink: 0;
}
.profile-popup .my-3 .row  .modal-img .img-fluid {
    width: 80px;
    height: 80px;
    border-radius: 999px;
}

.profile-popup .my-3 .row  .modal-img-text h6{
    padding-left: 24px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

}
/* .aside-bg {
  background-image: url('https://searchengineland.com/figz/wp-content/seloads/2015/10/google-maps2-fade-ss-1920.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-right: 48px;
} */
/* .aside-bg::before {
  background: rgba(255, 255, 255, 0.6) none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
} */

aside {
    position: relative;
    background-color: #cc0605;
    width: 100%;
    padding: 10px 20px;

}

body .layout
{
    margin-top: 70px;
}
.map-container {
    width: 100%;
}
.aside-bg aside, .map-container {
    height: calc(100vh - 70px);
    overflow: hidden;
}

.white_bg
{
    background:#fff;
}
aside h6 {
    font-family: 'Avenir-Book';
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    opacity: 1;
    font-size: 12px;
}
aside .search-input {
    position: relative;
}
aside .search-input .search-icon,
aside .search-input .location-icon {
    position: absolute;
    top: -17px;
    right: -1px;
    height: 18px;
    /* width: 18px; */
    color: #fff;
    background: #cc0605;
    padding: 28px 11px;
}
aside .search-input .location-icon {
    right: -1px;
    position: absolute;
    top: 46px;
    padding: 21px 11px;
}
aside .search-input input {
    background: transparent;
    border: 0;
    border-bottom: 0.3px solid rgba(255, 255, 255, 0.8);
    border-radius: 0;
    box-shadow: none;
    padding: 7px 0;
    color: #fff;
    font-size: 16px;
    font-family: 'Avenir 85';
    width: 100%;
    margin-bottom: 12px;
    outline: 0;
}
aside .search-btn button {
    background: transparent;
    padding: 9px 15px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.8);
    font-family: 'Avenir 85';
}
aside .search-input .search-btn a:hover {
    text-decoration: none;
}
aside .search-input input:focus {
    background-color: transparent;
    box-shadow: none;
    color: #fff;
    border-bottom: 0.3px solid rgba(255, 255, 255, 0.8);
}
aside .search-input input::-webkit-input-placeholder {
    color: #fff !important;
    font-size: 16px;
    font-family: 'Avenir 85';
}
aside .search-input  .form-control::placeholder {

    color: #fff !important;
    font-size: 16px;
    font-family: 'Avenir 85';

}
aside .search-input input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff !important;
    font-size: 16px;
    font-family: 'Avenir 85';
}

aside .search-input input::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff !important;
    font-size: 16px;
    font-family: 'Avenir 85';
}
aside .form-group {
    display: block;
    margin-bottom: 15px;
}

aside .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
aside .form-group label {
    position: relative;
    cursor: pointer;
    color: #fff;
    margin-right: 36px;
    display: flex;
    font-size: 15px;
    font-family: 'Avenir-Book'
}
aside .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 3px;
    margin-top: 0px;
}
aside .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 15px;
    border: solid #fff;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    background-color: #fe2e56;
}
aside .form-group label span {
    margin-left: 6px;
    vertical-align: middle;
}


aside.aside-company .search-input .autocomplete-dropdown-container  {
    position: absolute;
    Z-INDEX: 999;
}

aside.aside-company .search-input .autocomplete-dropdown-container .location-loaders {
    padding: 12px;
    border-radius: 50px;
    box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75);
}
aside .aside-profile {
    background: #fff;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    position: relative;
    -webkit-box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
}
aside .aside-profile.active-profile {
    background: #007AFF;
}
aside .aside-profile .profile-img {
    float: left;
}

aside .aside-profile .profile-img img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    object-fit: cover;
}
aside .aside-profile .profile-img-content {
    padding-left: 10px;
    margin-top: 8px;
}
aside .aside-profile .profile-pro {
    position: absolute;
    bottom: 15px;
    right: 15px;
}
aside .aside-profile .profile-img-content h6 {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Avenir-Book'
}
aside .aside-profile.active-profile .profile-img-content h6 {
    color: #fff;
}
aside .aside-profile .profile-img-content .profile-img-content-h6 {
    color: #BEC2CE;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Avenir-Book'
}
aside .aside-profile .profile-img-content h6 .km {
    font-weight: normal;
}
aside .aside-profile .profile-img-content .profile-camera {
    position: absolute;
    right: 13px;
    top: 22px;
}
aside .aside-profile .profile-img-content .profile-camera img {
    width: 17px;
    height: 14px;
}
aside .profile-achortag a {
    color: #fff;
    opacity: 0.8;
    font-size: 14px;
    text-decoration: underline;
    font-family:'Avenir-Book';
}
aside.aside-photo-profile {
    padding: 0;
    position: relative;
}
aside.aside-photo-profile img {
    width: 100%;
    max-height: 210px;
    object-fit: cover;
}
aside.aside-photo-profile .arrow-icon {
    color: #000;
    cursor: pointer;
    padding-top: 20px;
}
aside.aside-photo-profile .arrow-icon button {
    padding: 0;
    border: 0px solid #000;
    background-color: transparent;
    font-family: 'Avenir';
    font-weight: 600;
    color: #000;
}
aside.aside-photo-profile .arrow-icon button:hover {
    color: #000 !important;
}
aside.aside-photo-profile .aside-person-detail {
    margin-top: -40px !important;
    position: relative;
    background-color: #fff;
    padding: 0px 24px 40px !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin: auto;
    width: 90%;
    height: calc(100vh - 240px);
    overflow: auto;
}
aside.aside-photo-profile .aside-person-detail .map-icons {
    color: #BEC2CE;
    font-size: 12px;
}
aside.aside-photo-profile .aside-person-detail .profile-location-add ul a:hover {
    text-decoration: none;
}
aside.aside-photo-profile .aside-person-detail .profile-location-add ul a i {
    color: #cc0605;
}
aside.aside-photo-profile .aside-person-detail .profile-location-add ul a span {
    display: inherit !important;
    color: #000;
    font-size: 16px;
    font-family: 'Avenir-Book';
    font-weight: 700;
    vertical-align: text-bottom;
}
aside.aside-photo-profile .aside-person-detail .photography-heading {
    margin-top: 10px;
}
aside.aside-photo-profile .aside-person-detail .photography-heading h5 {
    color: #000;
    font-family: Avenir 85;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: inherit;
    text-transform: capitalize;
}
aside.aside-photo-profile .aside-person-detail .photography-heading .more-btn {
    color: #000;
    text-decoration: underline;
    font-family:'Avenir-Book';
    margin-top: 3px;
    padding: 0;
    font-size: 12px;
}
aside.aside-photo-profile .aside-person-detail .photography-heading .more-btn:active,
aside.aside-photo-profile .aside-person-detail .photography-heading .more-btn:focus {
    outline: 0;
    box-shadow: none;
}
aside.aside-photo-profile .aside-person-detail .photographer-img {
    float: left;
    /*	height: 30px;
        width: 30px;*/
}
aside.aside-photo-profile .aside-person-detail .photographer-img img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
}
aside.aside-photo-profile .aside-person-detail .photographer-name {
    color: #000;
    padding-left: 16px;
    font-size: 13px;
    font-family:'Avenir-Book';
    font-weight: 700;
    margin-bottom: 0;
}

.profile-popup .photographer-name a {
    color: white;
    font-weight: bold;
}

aside.aside-photo-profile .aside-person-detail .photographer-text {
    margin: 24px 0;
    color: #000;
    font-size: 11px;
    font-family:'Avenir-Book';
    font-weight: 500;
}
aside.aside-photo-profile .aside-person-detail .detailofphoto {
    color: #000;
    font-size: 16px;
    line-height: 1.3;
    /*height: 122px;*/
    overflow: hidden;
}
aside.aside-photo-profile .aside-person-detail .Previous-work {
    color: #1E2432;
    font-weight: bold;
}
aside.aside-photo-profile .aside-person-detail .previous-work-container {
    padding-bottom: 100px;
}
aside.aside-photo-profile .aside-person-detail .previous-shots img {
    height: 60px;
    min-width: 60px;
    max-width: 60px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 8px;
}
aside.aside-photo-profile .aside-person-detail .calling-btns a {
    background-color: #cc0605;
    color: #fff;
    padding: 11px 20px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    font-family: 'Avenir 85';
    display: block;
}
aside.aside-photo-profile .aside-person-detail .meassage-btn {
    position: absolute;
    width: 100%;
    bottom: 45px;

}
aside.aside-photo-profile .aside-person-detail .meassage-btn a {
    padding: 6px 20px;
    margin: 0 8px;
}
/***************** Aside Section End********************/
/********************* Section Start *****************/
section.blog-container {
    max-width: 97%;
    width: 100%;
}
section .near-profiles-mb {
    padding: 12px 11px 23px 15px;
}
section .near-profiles-mb h6 {
    font-size: 17px;
    font-family: "Avenir 85";
}
section .near-profiles-mb .profile-bg {
    background-color: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
}
section .near-profiles-mb .profile-bg img {
    padding: 5px;
    width: 100%;
    border-radius: 3px;
    height: 137px;
    object-fit: contain;
}
section .near-profiles-mb .profile-bg .profile-text {
    padding: 0px 5px;
}
section .near-profiles-mb .profile-bg .profile-text h6 {
    font-size: 15px;
    font-family: 'Avenir 85';
}
section .near-profiles-mb .profile-bg .profile-text img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    margin-top: -4px;
}
section.company-section {
    position: relative;
    z-index: 99999;
}
/********************* Company Profile *****************/
header .company-profile-arrow a i {
    color: #000;
    font-size: 31px;
}
section .company-img img {
    width: 291.26px;
    height: 217.3px;
    object-fit: contain;
}
section .create-btn {
    width: 30%;
    margin: auto;
}
section .com-wrapper-contain {
    margin-bottom: 50px;
    position: relative;
    z-index: 9999;
}
section .com-wrapper-contain .comapany-bg {
    position: relative;
    -webkit-box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
    padding: 20px 135px 20px 12px;
    background-color: #fff;
    border-radius: 5px;
}
section .com-wrapper-contain .comapany-bg .com-img-round {
    float: left;
    height: 60px;
    width: 60px;
}
section .com-wrapper-contain .comapany-bg .com-img-round img {
    width: auto;
}
section .com-wrapper-contain .comapany-bg .profile-img-content {
    padding-left: 70px;
}
section .com-wrapper-contain .comapany-bg .profile-img-content .profile-camera {
    position: absolute;
    top: 18px;
    right: 17px;
    width: 22px;
    height: 18px;
}
section .com-wrapper-contain .comapany-bg .profile-img-content h6 {
    font-size: 15px;
}

section .create-company a {
    display: block;
    padding: 12px;
    background-color: #cc0605;
    font-size: 20px;
    font-family: 'Avenir 85';
    color: #fff;
    width: 25%;
    margin: auto;
    border-radius: 6px;
}
section .create-company a:hover {
    text-decoration: none;
}
section .com-wrapper-contain h3 {
    font-size: 24px;
    color: #1E2432;
    font-family: 'Avenir 85';
    font-weight: 900;
    margin-top:80px;
}
.company_lists
{
    flex-wrap: wrap;
    justify-content: left;
}
section .com-wrapper-contain .company-detail-bg {
    position: relative;
    background-color: #fff;
    margin-right: 3em;
    max-width: 300px;
    min-width: 300px;
    border-radius: 8px;
    box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
    padding: 15px;
}
section .com-wrapper-contain .company-profile-img img {
    width:85px;
    height: 85px;
    object-fit:cover;
    border-radius: 50%;
}
section .com-wrapper-contain .company-profile-img {
    float: left;
    width: 100px;
    height: 80px;
}
section .com-wrapper-contain .comapny-heading {
    padding-left: 66px;
}
section .com-wrapper-contain .comapny-heading h6 {
    font-size: 15px;
    margin-top: 6px;
    font-family: 'Avenir-Book';
    white-space: nowrap;
    width: 109px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
section .com-wrapper-contain .comapny-heading h6 span {
    color: #BEC2CE;
    font-family: 'Avenir-Book';
}
/*section .com-wrapper-contain .company-camera {
  position: relative;
}*/
section .com-wrapper-contain .profile-camera {
    position: absolute;
    top: 11px;
    right: 17px;
    width: 22px;
    height: 18px;
}
section .com-wrapper-contain .profile-camera img {
    width: 22px;
    height: 18px;
    object-fit: contain;
}
section .company-profile {
    cursor: pointer;
    height: 142px;
    width: 142px;
    object-fit: contain;
    border: 6px solid #fff;
    border-radius: 50%;
    margin: auto;
    position: relative !important;
    -webkit-box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 9px -4px rgba(0,0,0,0.75);
}
section .company-profile img {
    border-radius: 50%;
}
section .company-profile .camera-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    right: 0px;
    top: 96px;
    background: #cc0605;
    box-shadow: none;
    border-radius: 50%;
    padding-top: 6px;
}
section .company-profile .camera-icon img {
    /*position: absolute;*/
    width: 54%;
}
/********************* Company Profile *****************/
/********************* Section End *****************/
/********************* Footer Start *****************/
footer {
    background-color: #fff;
    padding: 16px 0;
}

footer p {
    line-height: 2;
    color: #343b41;
    font-size: 12px;
    font-family: 'Avenir 85';
    margin-bottom: 0;
}
/********************* Footer End *****************/
/********************* Sign Up Page Start *****************/
.signup-bg {
    background-color: #cc0605;
    text-align: center;
    /* padding: 231.5px 0; */
    display: table;
    height: 100vh;
    width: 100%;
}
.signup-bg .signup-detail {
    margin: auto;
    display: table-cell;
    vertical-align: middle;
    float: none;
}
.signup-bg img {
    max-width: 80%;
    object-fit: contain;
}
.signup-form {
    /*padding: 83px 108px;
    background-color: #fff;*/
    background-color: #fff;
    display: table;
    width: 100%;
    height: 100vh;
}
.signup-form .signup-here form {
    width: 65%;
    margin: auto;
}
.signup-form .signup-here {
    display: block;
    width: 100%;
    position: relative;
    z-index: 9999;
}
.signup-form .signup-here .srv-validation-message {
    color: red;
}
.signup-form h2 {
    font-family: 'Avenir 85';
    font-size: 44px;
}
.signup-form label {
    font-family: 'Avenir-Book';
    color: #000;
    opacity: 0.5;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}
.signup-form input {
    font-family: 'Avenir 85';
    /*color: #fff;*/
    padding: 0 0 8px 0;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid rgba(224, 224, 224, 0.5);
    font-size: 24px;
}
.signup-form input:focus {
    box-shadow: none;
    border-bottom: 2px solid rgba(224, 224, 224, 0.5);
}
.signup-form button {
    padding: 10px;
    background-color: #cc0605;
    border: 0;
    font-size: 15px;
    font-family: 'Avenir 85';
    border-radius: 9px;
}
.signup-form button:focus {
    box-shadow: none;
    background-color: #cc0605;
}
.signup-form button:hover {
    background-color: #cc0605;
}
.signup-form  p {
    color: #000;
    font-size: 24px;
}
.signup-form  p a {
    text-decoration: underline;
    color: #000;
    font-weight: 600;
    font-size: 24px;
}
.signup-bg .round-indicators {
    bottom: -85px !important;
}
.signup-bg .round-indicators li {
    border-radius: 50px;
    height: 12.2px !important;
    width: 12.2px !important;
}
.signup-bg .carousel-img .carousel-item {
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -o-center;
}
.signup-bg h1 {
    font-family: 'Avenir';
    font-weight: 900;
    color: #fff;
    font-size: 64px;
}
.signup-bg .login-img img {
    width: 512.79px;
    height: 423.82px;
    object-fit: contain;
}
.login-here a {
    font-size: 18px;
    color: #cc0605;
}
.login-here button.fb-btn {
    background-color: #055EEE !important;
}
.login-here button.insta-btn {
    background-color: #5856D6 !important;
}
.login-img img {
    width: 512.79px !important;
    height: 512.79px;
    object-fit: contain;
}
.login-slider .carousel-indicators {
    bottom: -67px;
}
.login-slider .carousel-indicators li {
    border-radius: 50%;
    height: 18px;
    width: 18px;
}
.login-slider .carousel-img .carousel-control-next-icon,
.login-slider .carousel-img .carousel-control-prev-icon {
    display: none;
}
/********************* Sign Up Page End *****************/
/********************* company details *****************/
.company-detail-page .profile-detail-box {
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    padding: 11px 17px;
}
.company-detail-page .profile-detail-box .activity-drop {
    width: 50%;
}
.company-detail-page .profile-detail-box .form-group label {
    font-size: 20px;
    color: #BEC2CE;
    font-family: 'Avenir-Book';
}
.company-detail-page .profile-detail-box .form-group input, .company-detail-page .profile-detail-box .form-group div.readonly {
    font-size: 24px;
    width: 100%;
    border-radius: 0;
    border: 0;
    background-color: #fff;
    border-bottom: 1px solid #cccc;
    padding: 0px 0 8px 0;
    color: #000;
    font-family: 'Avenir 85';
    box-shadow: none !important;
}
.company-detail-page .profile-detail-box .form-group div.readonly {
    font-size: 16px;
    border-bottom: 0;
    min-height: 2.5em;
}
.company-detail-page .profile-detail-box .profile-toggle span {
    font-size: 12px;
    color: #000000;
    margin-top: -2px;
    display: block;
    padding-right: 3px;
}
.company-detail-page .profile-detail-box .form-group select {
    width: 100%;
    padding: 0px 25px 11px 0;
    color: #1E2432;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #cccc;
    font-size: 24px;
    font-family: 'Avenir 85', sans-serif;
    background: transparent url(../images/expand-arrow.png) no-repeat;
    background-color:  #fff;
    background-size: 14px;
    background-position: 95% center;
    -webkit-appearance: none;
    box-shadow: none !important;
}
.company-detail-page .address-heading {
    color: #1E2432;
    font-size: 24px;
    font-family: 'Avenir 85';
    font-weight: 900;
}

.company-detail-page .none-heading {
    visibility: hidden;
}
.company-detail-page .profile-detail-box .description-form {
    width: 100%;
}
.profile_img {
    margin: 0px auto;

    display: block;
}

.image_pic .user-profile-pic {
    position: relative !important;
}

.image_pic .user-profile-pic .delete {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -7px;
    top: -7px;
    padding: 0;
    border: none;
    background: white;
}

.image_pic .user-profile-pic .delete img {
    width: 15px;
    height: 15px;
    padding: 0;
}

.company-detail-page .user-profile-pic img {
    width: 145px;
    height: 109px;
    object-fit: contain;
    padding: 10px;
}
.company-detail-page .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
}
.company-detail-page .upload-btn-wrapper  p {
    font-size: 16px;
    color: #1e2432;
}
.company-detail-page .upload-photo-btn {
    color: #fff;
    background-color: #5555FF;
    padding: 25px 0;
    border-radius: 8px;
    font-size: 24px;
    font-family: 'Avenir 85';
    margin-top: 9px;
}
.company-detail-page .upload-btn-wrapper input[type=file] {
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 32px 1px;
    opacity: 0;
}
.company-detail-page .saving-publiah-btn {
    background-color: #cc0605;
    border: 0;
    font-size: 20px;
    font-family: 'Avenir 85';
    padding: 12px;
    border-radius: 7px;
}
.company-detail-page .saving-publiah-btn:hover {
    background-color: #cc0605;
}
.company-detail-page .saving-publiah-btn:focus {
    box-shadow: none;
    background-color: #cc0605;
}
.company-detail-page .saving-publiah-btn.btn-danger:not(:disabled):not(.disabled):active {
    background-color: #cc0605;
    box-shadow: none;
}
.company-detail-page .detail-heading h1 {
    color: #1E2432;
    font-size: 37px;
    font-family: 'Avenir 85';
    margin-left: 12px;
}

/********************* modal design part *****************/
.profile-popup .modal-dialog {
    max-width: 1524px;
}
.profile-popup .modal-dialog .modal-content {
    padding: 18px;
    border-radius: 1.3rem;
}
.profile-popup .modal-dialog .modal-content .modal-body {
    padding-top: 0;
    padding-bottom: 0;
}
.profile-popup .modal-dialog .modal-content .modal-bg button {
    color: #1E2432;
    font-size: 32px;
    opacity: 1;
    /* margin-right: -6px; */
    margin-top: -28px;
    margin-right: 6px;
}
.profile-popup .modal-dialog .modal-content .modal-bg button:focus {
    outline: 0;
}
.profile-popup .modal-content .col-md-4 {
    padding-left: 8px;
}
.profile-popup .modal-content .model-left-sec {
    background-color: #cc0605;
    padding: 30px 38px;
    border-radius: 7px;
}
.profile-popup .modal-content .model-left-sec .model-left-profile {
    float: left;
    /*height: 121px;
    width: 121px;
    object-fit: contain;*/
}
.profile-popup .modal-content .model-left-sec .profile-location-add ul {
    list-style-type: none;
}
.profile-popup .modal-content .model-left-sec .profile-location-add ul a {
    color: #fff;
    font-family: 'Avenir-Book';
    font-size: 15px;
    line-height: 1.2;
}
.profile-popup .modal-content .model-left-sec .profile-location-add ul a:hover {
    text-decoration: none;
}
.profile-popup .modal-content .model-left-sec .profile-location-add ul a span {
    display: inherit;
}
.profile-popup .modal-content .model-left-sec .model-left-profile img {
    width: 100%;
    border-radius: 50%;
    width: 96px;
    height: 96px;
    object-fit: cover;
}
.profile-popup .modal-content .model-left-sec .model-address-descp {
    max-height: 200px;
    overflow: hidden;
}
.profile-popup .modal-content .model-left-sec .model-address-descp:hover {
    overflow-y: scroll;
}
.profile-popup .modal-content .model-left-sec .model-left-rating {
    padding-left: 114px;
    margin-top: 17px;
}
.profile-popup .modal-content .model-left-sec .model-left-rating h6 {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Avenir-Book';
}
.profile-popup .modal-content .model-left-sec p {
    color: #fff;
    font-family: 'Avenir-Book';
    line-height: 24px;
    font-size: 12px;
    font-weight: 400;
    list-style: none;
    margin-top: 40px;
}

.profile-popup .modal-content .model-left-sec ul {
    list-style: none;
    margin: 32px 0;
}

.profile-popup .modal-content .model-left-sec ul li {
    color: #fff;
    font-family: 'Avenir-Book';
    line-height: 24px;
    font-size: 12px;
    font-weight: 400;
    list-style: none;

}

.profile-popup .modal-content .model-left-sec ul li a {
    color: #fff;
    list-style: none;
}
.profile-popup .modal-content .calling-btns {
    margin-top: 7px;
}
.profile-popup .modal-content .calling-btns a {
    display: block;
    background-color: #cc0605;
    color: #fff;
    padding: 11px 0;
    font-size: 14px;
    font-weight: bold;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    font-family: 'Avenir 85';
}
.profile-popup .modal-content .model-right-side {
    padding: 0 0px 0 62px;
}
.profile-popup .modal-content .model-right-side h5 {
    /*font-family: 'Avenir 85' !important;*/
    font-size: 16px !important;
    /*font-weight: 700;*/
    color: #1E2432;
    font-family: 'Avenir' !important;
    font-weight: 900;
    margin-bottom: 10px !important;
}
.profile-popup .modal-content .model-right-side .model-right-profile-pics {
    box-shadow: 0px 2px 12px -5px rgba(17, 13, 13, 0.75);
    border-radius: 0;
    margin-bottom: 23px;
}
.profile-popup .modal-content .model-right-side .model-right-profile-pics img {
    width: 100%;
    max-height: 152px;
    object-fit: cover;
}

.userProfileImg
{
    object-fit: cover;
    width: 120px !important;
    height: 120px !important;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0px 5px 10px #BEC2CE;
    margin-left: 17px;
}
.file_uploader_profile a.fileUploaderBtn
{
    background-color: #ff2d55;
    color: #fff;
    font-size: 18px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 37px;
    position: absolute;
    top: 89px;
    right: 10px;

}
.profile_img
{
    position: relative;
}
.file_uploader_profile input[type="file"]
{
    display: block;
    position: absolute;
    top: 0px;
    z-index: 9;
    width: 140px;
    height: 140px;
    outline: none;
    opacity: 0;
}
.images_uploads_bottom {
    display: flex;
    flex-wrap: wrap;
}


/********************* Media Query Start *****************/
@media (max-width: 1800px) and (min-width: 1400px) {
    /*.map-button button {
        margin-left: 128px !important;
    }*/
    .profile-popup .modal-dialog {
        max-width: 1350px !important;
    }
}
span.testspan {
    /*margin-left: 606px !important;*/
}
.signup-form h2 {
    font-size: 37px;
    margin-bottom: 17px !important;
}
.signup-form label {
    font-size: 15px;
}
.signup-form button {
    margin-top: 30px !important
}
.signup-form .form-group {
    margin-bottom: 10px;
}
.signup-form input {
    padding: 0 0 4px 0;
    font-size: 16px;
}
.signup-form button {
    font-size: 16px;
}
.signup-form p,
.signup-form p a {
    font-size: 16px;
}
.signup-bg {
    display: -webkit-box;
}
.login-img img {
    max-width: 55%;
}
.signup-bg .round-indicators {
    bottom: -48px !important;
}
.signup-bg h1 {
    font-size: 50px;
}
.login-here a {
    font-size: 14px;
}
.login-here h2 {
    margin-bottom: 44px !important;
    margin-top: 50px;
}
.login-here p {
    margin-top: 12px !important;
}
.login-slider .carousel-indicators {
    bottom: 23px;
}
.login-slider .carousel-indicators li {
    height: 12px;
    width: 12px;
}
header.map-bg .header-signup-btn button {
    font-size: 15px;
    margin-top: 0 !important;
}
aside {
    max-width: 338px;
}
aside h6 {
    font-size: 14px;
}
aside .aside-profile .profile-img-content h6 {
    font-size: 13px;
}
section .near-profiles .profile-bg .profile-text h6,
header .sidebar-wrapper .sidebar-header .user-info .vip-btn {
    font-size: 13px;
}
header .sidebar-content {
    padding: 40px 70px;
}
header .sidebar-wrapper .sidebar-header .user-name {
    font-size: 15px;
}
header .chiller-theme .sidebar-wrapper .sidebar-menu ul li a {
    font-size: 14px;
}
aside.aside-photo-profile .aside-person-detail .calling-btns a {
    padding: 11px 10px;
}
aside.aside-photo-profile .aside-person-detail .meassage-btn a {
    padding: 11px 10px;
}
header .bg-white {
    padding: 0 !important;
}
header .humburger-img img {
    height: 27.94px;
    width: 23.94px;
    object-fit: contain;
}
.profile-popup .modal-dialog {
    max-width: 1200px;
}
.profile-popup .modal-dialog .modal-content .modal-bg button {
    font-size: 40px;
}
.profile-popup .modal-content .calling-btns a,
.profile-popup .modal-content .model-left-sec .model-left-rating h6 {
    font-size: 14px;
}
.profile-popup .modal-content .model-left-sec p {
    font-size: 15px;
}
.profile-popup .modal-content .model-right-side h5 {
    font-size: 20px;
}
.profile-popup .modal-content .model-left-sec i {
    color: white;
}

.profile-popup .modal-content .model-left-sec div {
    color: white;
}

aside .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 3px;
    margin-top: 0px;
}
aside .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 15px;
    border: solid #fff;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    background-color: #fe2e56;
}
aside .form-group label span {
    margin-left: 4px;
    vertical-align: middle;
}
section .near-profiles-mb h6 {
    font-size: 12px !important;
}
aside .aside-profile .profile-img img {
    height: 58px;
    width: 58px;
}
aside .aside-profile .profile-img-content .profile-camera {
    top: 17px;
}
aside.aside-photo-profile .aside-person-detail .photography-heading h5 {
    font-size: 22px;
    color: #1E2432;
    margin: 0;
}
aside.aside-photo-profile .aside-person-detail .photography-heading .more-btn {
    font-size: 13px;
}
aside.aside-photo-profile .aside-person-detail .photographer-name {
    font-size: 13px;
}
footer {
    margin-top: 0;
}
.company-detail-page .profile-detail-box .form-group label,
.company-detail-page .profile-detail-box .form-group input,
.company-detail-page .profile-detail-box .form-group select  {
    font-size: 16px;
}
.company-detail-page .profile-detail-box .profile-toggle span {
    font-size: 10px;
}
.company-detail-page .upload-photo-btn,
.company-detail-page .saving-publiah-btn {
    font-size: 16px;
}
.company-detail-page .upload-btn-wrapper p {
    font-size: 12px;
}
section .com-wrapper-contain h3 {
    font-size: 16px;
}
section .create-company a {
    font-size: 15px;
}
.login-here span button.kep-login-facebook {
    margin-top: 1px !important;
}
.login-here .google_btns button {
    margin: 27px 0 !important;
}
.login-here .google_btns button span {
    font-size: 16px !important;
}
.login-here .insta_btn button {
    margin-top: 0 !important;
    font-size: 16px !important;
}
.wrapper-container section .com-wrapper-contain.company-detail-page {
    max-width: 1140px !important;
}
@media (max-width: 767px) {
    header .map-button button {
        top: 76px;
    }
    header .map-icon img {
        z-index: -3;
    }
    /*aside {
        max-width: 50%;
    }*/
    section .near-profiles .near-profiles-mb {
        margin-bottom: 20px;
    }
    aside.aside-photo-profile {
        max-width: 53% !important;
    }
    aside.aside-photo-profile .aside-person-detail .calling-btns a {
        padding: 11px 56px;
    }
    aside.aside-photo-profile .aside-person-detail .meassage-btn a {
        padding: 11px 30px;
    }
}
@media (max-width: 575.85px) {
    /*.container {
        display: none;
    }*/
}
span {
    display: inline-flex;
}
span.testspan {
    /*margin-left: 745px;*/
}
.header-container {
    min-height: 70px;
}
.header-left, .header-right {
    min-width: 100px;
}
.header-right {
    text-align: right;
}
img.logo_image_fluid {
    width: 56.66px;
    height: 56.32px;
    cursor: pointer;
    object-fit: cover;
}
.goBack_button{
    color: black;
    margin-top: 8px;
    cursor: pointer;
}
.goBack_button i {
    font-size: 35px;
    margin-left: 15px;
}
.btn-header, .btn-header:hover {
    height: 44px;
    background-color: #cc0605;
    color: #fff;
    font-family: 'Avenir-Medium';
}
.btn-link-header {
    color: #ff2d55;
}
.btn-link-header:hover, .btn-link-header:focus, .btn-link-header:active {
    color: #ce2745;
    text-decoration: none;
    box-shadow: none !important;
}
button.btn.btn-danger.mr-3.pull-right:focus {
    box-shadow: none !important;
}
.profile-toggle {
    min-width: 80px;
}
.profile-detail-box .profile-toggle .react-switch {
    margin-left: 12px;    float: right;
}
.profile-detail-box .profile-toggle .react-switch-bg {
    height: 24px !important;
    width: 50px !important;
    background-color: transparent !important;
    border: 1px solid #ccc !important;
}
.profile-detail-box .profile-toggle .react-switch-bg>div {
    height: 24px !important;
    width: 50px !important;
    background: rgb(85, 85, 255);
    border-radius: 50px;
    width: auto !important;
}
.profile-detail-box .profile-toggle .react-switch-handle {
    height: 18px !important;
    width: 18px !important;

    border: 1px solid #ccc !important;
    top: 3px !important;
    left: 2px !important;
}
.sidebar-header .user-pic img {
    width: 80px;
    height: 80px;
    object-fit: cover;
}
.sidebar-header .user-info .user-name {
    font-family: 'Avenir';
    font-weight: 900;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
}
.sidebar-header .user-info .vip-btn {
    background-color: #662DFF;
    border: 0;
    padding: 7px 15px;
    font-size: 14px;
    font-family: 'Avenir-Medium';
}
.sidebar-header .user-info .vip-btn:focus {
    box-shadow: none;
}
.sidebar-content .sidebar-brand #close-sidebar {
    color: #fff;
    position: absolute;
    top: 86px;
    font-size: 26px;
    right: 42px;
    width: 21.79px;
    height: 21.79px;
    cursor: pointer;
}
.sidebar-content .sidebar-menu ul li a {
    display: inline-block !important;
    width: 100% !important;
    text-decoration: none !important;
    position: relative !important;
    padding: 7px 0px 2px 20px !important;
    color: #fff !important;
    font-size: 16px;
    font-family: 'Avenir-Book';
}
.sidebar-content .sidebar-menu ul {
    list-style-type: none;
    padding: 20px 21px;
    margin: 0;
}
.sidebar-content .sidebar-menu ul li a i {
    margin-right: 10px;
    font-size: 17px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 6px;
    background: #BF30FF;
}
.sidebar-content .sidebar-menu ul li a img {
    background: #BF30FF;
    padding: 5px;
    border-radius: 6px;
    width: 11%;
}
.profile_container {
    padding-top: 90px; /* navbar offset */
    background-color: #fff;
}
.sidebar-content .sidebar-menu ul li a i.logout {
    background-color: #5AC8FA !important
}
.sidebar-content .sidebar-menu ul li a i.notifi {
    background-color: #FF3B30 !important
}
.sidebar-content .sidebar-menu ul li a i.cards {
    background-color: #5AC8FA !important
}
.sidebar-content .sidebar-menu ul li a i.lang {
    background-color: #5856D6 !important
}
.sidebar-content .sidebar-menu ul li a i.lang {
    background-color: #5856D6 !important
}
.sidebar-content .sidebar-menu ul li a i.currency {
    background-color: #FF9500 !important
}
.sidebar-content .sidebar-menu ul li a i.terms {
    background-color: #000000 !important
}
.sidebar-content .sidebar-menu ul li a i.policy {
    background-color: #4CD964 !important
}
.sidebar-content .sidebar-menu ul li a i.help {
    background-color: #FFCC00 !important
}
.sidebar-content .sidebar-menu ul li a i.about {
    background-color: #007AFF !important
}
.sidebar-content .sidebar-menu .sidebar-dropdown > a:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
    font-style: normal;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    position: absolute;
    right: 60px;
    top: 9px;
    color: #C7C7CC;
}
.custom-sidebar-class>div>div {
    background-color: #cc0605 !important;
    z-index: 0 !important;
    position: inherit !important;
}
.custom-sidebar-class {
    z-index: 99999 !important;
}
.map-button {
    position: relative;
}
.map-button button {
    position: absolute;
    top: 32px;
    /*margin-left: 84px;*/
    background-color: #fff;
    border-radius: 50px;
    padding: 16px 45px;
    font-size: 12px;
    -webkit-box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 8px -4px rgba(0,0,0,0.75);
    font-family: 'Avenir-Book';
    font-weight: 600;
    left: 0;
    right: 0;
    margin: auto;
    width: 160px;
}
.blue-radial {
    position: relative;
}
.blue-radial .blue-location-img {
    position: absolute;
    top: 177px;
    right: 0;
    left: 726px;
    margin: auto;
}
.map-icon3,
.map-icon4 {
    position: relative;
}
.map-icon3 img,
.map-icon4 img {
    top: 159px;
    margin-left: -113px !important;
    width: 35.54px;
    height: 35.54px;
    position: absolute;
    object-fit: contain;
}
.map-icon4 img {
    right: 444px;
}

aside.aside-signup {
    margin-top: 0px !important;
}
.login-here span {
    display: block;
}
.login-here span button.kep-login-facebook {
    width: 100%;
    border-radius: 6px;
    background-color: #055EEE;
    text-transform: capitalize;
}
.login-here .google_btns button {
    width: 100%;
    justify-content: center;
    padding: 12px;
    margin: 25px 0;
    font-family: 'Avenir 85';
    font-size: 20px;
    border-radius: 6px !important;
    box-shadow: rgba(123, 123, 123, 0.24) 0px 2px 7px 0px, rgba(123, 123, 123, 0.24) -1px 0px 8px 0px !important;
}
.login-here .google_btns button span {
    font-family: 'Avenir 85';
    font-size: 20px;
}
.login-here .insta_btn button {
    width: 100% !important;
    justify-content: center;
    padding: 12px;
    background: #5856D6 !important;
    border: 0 !important;
    font-size: 14px !important;
    font-family: 'Avenir 85' !important;
    font-size: 16px !important;
    border-radius: 9px !important;
}
.wrapper-container .profile-button {
    background-color: transparent !important;
    border-color: transparent;
}
.wrapper-container .profile-button img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 9px 3px;
}
.wrapper-container .profile-button:hover, .wrapper-container .profile-button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
}
.wrapper-container .map-container .profile-text h6 {
    color: #000;
    font-size: 12px;
    font-family: 'Avenir-Book';
    font-weight: 700;
    margin-top: 8px;
}
.wrapper-container .map-container .profile-text img {
    width: 15.49px;
    height: 11.67px;
    margin-top: 9px !important;
    margin-right: 5px;
    object-fit: contain;
}
body {
    background-color: #fff !important;
}
.wrapper-container section .com-wrapper-contain.company-detail-page {
    max-width: 1315px;
}

.gm-style .gm-style-iw-c {
    padding: 7px !important;
}
.gm-style .gm-style-iw-d {
    overflow: auto !important;
}
.gm-style-iw.gm-style-iw-c .gm-ui-hover-effect {
    display: none !important;
}
@media (max-width: 991px) {
    section.meassage-page .signup-form form {
        width: 68% !important;
    }
}
@media (max-width: 767px) {
    .signup-form .signup-here {
        display: unset;
    }
    .signup-form .signup-here form {
        width: 96%;
        margin: auto;
    }
    .signup-form h2 {
        margin-top: 30px;
    }
    .signup-form img.cross_img {
        width: 6%;
    }
    section .create-company a {
        width: 327px;
    }
    .company-detail-page .profile-detail-box .justify-content-between {
        flex-direction: row-reverse;
    }
    .company-detail-page .profile-detail-box .justify-content-between .profile-toggle {
        flex-direction: column-reverse;
        display: flex;
        align-self: flex-start;
    }
    .company-detail-page .profile-detail-box .justify-content-between .form-group {
        width: 100%;
    }
    .company-detail-page .profile-detail-box .profile-toggle span {
        font-size: 13px;
        font-family: 'Avenir 85';
        /* display: inline-block; */
        /* margin-bottom: 12px; */
        margin-top: -25px;
    }
    .company-detail-page .profile-detail-box .justify-content-between .profile-toggle >div {
        align-self: start;
    }
    .profile-detail-box .profile-toggle .react-switch {
        margin-left: 0 !important;
    }
    .company-detail-page .profile-detail-box {
        border: 1px solid transparent;
    }
    .company-detail-page .address-heading {
        margin-bottom: 8px !important;
    }
    /*button.btn.btn-danger.mr-3.pull-right {
      right: -11px;
      margin-top: 9px !important;
    }*/
    .company-detail-page .none-heading {
        display: none;
    }
    button.btn.btn-danger.mr-3.pull-right {
        width: 85px !important;
        /* height: 37px !important; */
        right: -13px;
        background-color: transparent;
        border: 0;
        color: #3975FF;
    }
    button.btn.btn-danger.mr-3.profile-button {
        width: 85px !important;
        right: -16px;
    }
    section .near-profiles-mb .profile-bg img {
        object-fit: cover;
    }
    .company-detail-page .address-heading {
        font-size: 18px;
    }
    .images_uploads_bottom {
        justify-content: center;
    }
}
@media (max-width: 575.75px) {
    .wrapper-container .custom-sidebar-class > div {
        width: 310px !important;
    }
    .sidebar-content .sidebar-menu ul {
        padding: 0;
    }
    .sidebar-content .sidebar-menu ul li a {
        padding: 7px 0px 2px 0px !important;
    }
    .sidebar-content .sidebar-menu .sidebar-dropdown > a:after {
        right: 1px;
    }
    .custom-sidebar-class>div>div {
        background-color: #fff !important
    }
    .sidebar-content .sidebar-menu ul li a {
        color: #000 !important;
    }
    .sidebar-content .sidebar-menu ul li a i {
        color: #fff !important;
    }
    .sidebar-header .user-info .user-name {
        color: #000 !important;
    }
    .sidebar-header .user-info .user-name i {
        font-size: 12px;
        color: #C7C7CC;
        margin-left: 103px;
    }
    .sidebar-header .user-info .vip-btn {
        background-color: #cc0605 !important;
        padding: 5px 8px !important;
        font-size: 10px !important;
    }
    .sidebar-content .sidebar-brand #close-sidebar.cross_btn {
        top: 20px !important;
        right: 13px !important;
    }
    .sidebar-content .sidebar-header {
        min-height: 80px;
        text-align: left !important;
    }
    .sidebar-content .sidebar-header .user-pic {
        float: left;
    }
    .sidebar-content .sidebar-header .user-info {
        padding-left: 102px;
        padding-top: 3px;
    }
    aside.aside-photo-profile {
        max-width: 83% !important;
    }
    /*  aside {
        max-width: 76%;
      }*/
    section.meassage-page .signup-form form {
        width: inherit !important;
    }
    .company-detail-page .upload-photo-btn {
        padding: 14px 0;
    }
    .company-detail-page .upload-btn-wrapper input {
        padding: 14px 1px;
    }
    .btn-primary:not(:disabled):not(.disabled):active {
        background-color: #cc0605 !important;
        border-color: #cc0605 !important;
    }
    aside.aside-photo-profile .aside-person-detail .meassage-btn i {
        margin-right: 9px;
    }
    .profile-mobile-view .mobile-post-scroll .mobile-search-result {

    }
}
@media (max-width: 400px) {
    section .create-company a {
        width: 300px;
    }
    .signup-form .send-message {
        width: 124px;
        margin: auto;
    }
}
/********************* Profile mobile view **********************/
.profile-mobile-view {
    /*background-image: url(https://www.applied-streetview.com/wp-content/uploads/63225-Langen-Google-Maps.png);*/
    background-image: no-repeat;
    background-size: cover;
    /*height: 500px;*/
    padding-top: 15px;
    position: relative;
}
.profile-mobile-view .mobile-search-box .serach-box-pd {
    margin: 10px;
}
.profile-mobile-view .mobile-search-box .search-icon i {
    background: #F1F2F6;
    border-radius: 50%;
    padding: 14px;
    font-size: 20px;
}
.profile-mobile-view .mobile-search-box {
    margin: 0 15px;
    padding: 3px 11px 10px 0;
    background-color: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0px 2px 17px -5px rgba(45, 45, 45, 0.75);
    -moz-box-shadow: 0px 2px 17px -5px rgba(45, 45, 45, 0.75);
    box-shadow: 0px 2px 17px -5px rgba(45, 45, 45, 0.75);
}
.profile-mobile-view .mobile-search-box .search-grapher-post h5 {
    color: #BEC2CE;
    font-size: 15px;
    font-family: 'Avenir-Book';
    font-weight: normal;
}
.profile-mobile-view .mobile-search-box .search-grapher-post .checkboxes {
    height: 24px;
}
.profile-mobile-view .mobile-search-box .search-grapher-post {
    width: 100%;
}
.profile-mobile-view .mobile-search-box .search-grapher-post input {
    color: #000;
    border-bottom: 1px solid #BEC2CE;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    font-family: 'Avenir 85';
    font-weight: bold;
    padding-left: 0;
    width: 100%;
}
.profile-mobile-view .mobile-search-box .search-grapher-post input:focus {
    box-shadow: none;
    outline: 0;
}
.profile-mobile-view .mobile-post-scroll {
    display: flex;
    overflow-x: scroll;
    margin-top: 272px;
    margin-bottom: 15px;
}
.profile-mobile-view .mobile-post-scroll .mobile-search-result {
    margin-left: 28px;
}
.profile-mobile-view .mobile-post-scroll .result-img img {
    max-width: 134px;
    object-fit: contain;
    border-radius: 8px;
    min-width: 134px;
    height: 94px;
}
.profile-mobile-view .mobile-post-scroll h5 {
    font-size: 12px;
}
.profile-mobile-view .mobile-post-scroll .camera-ion {
    width: 12px;
    height: 12px;
    object-fit: contain;
}
.checkbox-mobile {
    display: block;
    position: relative;
    padding-left: 27px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Avenir-Book';
    font-weight: normal;
}

/* Hide the browser's default checkbox */
.checkbox-mobile input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    /* background-color: #eee; */
    border: 2px solid #313131;
    border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-mobile input:checked ~ .checkmark {
    background-color: #FFF;
    border: 2px solid #313131;
    border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-mobile input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-mobile .checkmark:after {
    left: 9px;
    top: -6px;
    width: 6px;
    height: 17px;
    border: solid #313131;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #fff;
}
.modal-backdrop {
    opacity: 0.5;
    z-index: 99999 !important;
}
.modal {
    z-index: 99999 !important;
}
.modal-footer .btn-cancel {
    background-color: #cc0605;
    border: 1px solid #cc0605;
    padding: 7px 28px;
    font-family: 'Avenir-Book';
}
.model-popup .modal-content {
    background-color: transparent;
    border: 0;
    border-radius: 0;
}
.model-popup .modal-header .close {
    color: #fff;
    opacity: 1;
    font-size: 32px;
    margin-right: -33px;
}
.model-popup .modal-body {
    background-color: #fff;
    font-family: 'Avenir-Book';
}
.model-popup .h4 {
    font-family: 'Avenir 85';
}
.model-popup .modal-body {
    font-family: 'Avenir-Book';
}
.model-popup .modal-footer {
    background-color: #fff;
    border-radius: 0;
    border-top: 0;
    justify-content: center;
}
button.home-button {
    right: 11px;
    /* bottom: -10px; */
    /* position: absolute; */
    top: 333px;
    padding: 2px;
    font-size: 23px;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    margin: 0 0 0 auto !important;
}
button.btn.btn-default.home-button:focus {
    box-shadow: none;
}

.mobile-post-scroll .mobile-search-result {
    background-color: #fff;
    padding: 12px 12px 5px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 17px -5px rgba(45, 45, 45, 0.75);
    -moz-box-shadow: 0px 2px 17px -5px rgba(45, 45, 45, 0.75);
    box-shadow: 0px 2px 17px -5px rgba(45, 45, 45, 0.75)
}
.mobile-post-scroll .mobile-search-result .content-heading {
    padding-top: 12px;
}

/********************* Login types page **********************/
.login-types {
    background-image: url("../images/mapp-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 450px;
}
.login-types .logo-img {
    padding: 130px 0;
}
.login-types .logo-img img {
    max-width: 53%;
    width: 100%;
}
.login_btn_page .fb-btn,
.login_btn_page .insta-btn,
.login_btn_page .email-btn {
    background-color: #055EEE;
    color: #fff;
    font-family: 'Avenir 85';
    font-size: 13pt;
    border-radius: 7px;
    width: 96%;
    margin: auto;
}
.login_btn_page a:active {
    text-decoration: none;
}
.login_btn_page a:hover {
    text-decoration: none;
}
.login_btn_page .fb-btn.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #055EEE !important;
    border: 1px solid #055EEE !important;
}
.login_btn_page .fb-btn:focus {
    box-shadow: none;
    background-color: #055EEE !important;
}
.login_btn_page .insta-btn {
    background-color: #5856D6 !important;
    border: 1px solid #5856D6 !important;
}
.login_btn_page .insta-btn.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #5856D6 !important;
    border: 1px solid #5856D6 !important;
}
.login_btn_page .insta-btn:focus {
    box-shadow: none;
    border: 1px solid #5856D6 !important;
}
.login_btn_page .email-btn {
    background-color: #cc0605;
}
.login_btn_page .email-btn:hover {
    background-color: #cc0605 !important;
    border: 1px solid #cc0605 !important;
    text-decoration: none;
}
.login_btn_page .email-btn.btn-danger:not(:disabled):not(.disabled):active {
    background-color: #cc0605 !important;
    border: 1px solid #cc0605 !important;
}
.login_btn_page .email-btn:focus {
    box-shadow: none;
    border: 1px solid #cc0605 !important;
    text-decoration: none;
}
.login_btn_page p,
.login_btn_page p a {
    color: #1E2432;
    font-family: 'Avenir';
    font-weight: 500;
}

.connect_pro img {
    width: 100%;
    max-height: 170px;
    min-height: 170px;
}
.connect_pro {
    position: relative;
}
.connect_pro h2 {
    position: absolute;
    top: 65px;
    /* color: #fff; */
    font-size: 22pt;
    color: #fff;
    font-family: 'Avenir 85';
    left: 30px;
    /* width: 340px; */
    width: 64%;
}
.connect_item_mobile .intro_slider {
    margin-bottom: 30px;
}
.connect_item_mobile .carousel-control-prev {
    left: 19px;
}
.connect_item_mobile .carousel-control-next {
    right: 19px;
}
.connect_item_mobile .carousel-control-prev,
.connect_item_mobile .carousel-control-next {
    top: 638px;
    bottom: 20px;
}
.connect_item_mobile .carousel-control-prev-icon,
.connect_item_mobile .carousel-control-next-icon {
    background-image: none !important;
    width: 100%;
    color: #000;
}
.connect_item_mobile .carousel-control-prev-icon::before {
    content: "SKIP";
    cursor: pointer;
    font-family: 'Avenir';
    font-weight: 500;
}
.connect_item_mobile .carousel-control-next-icon::before {
    content: "NEXT";
    cursor: pointer;
    font-weight: 500;
    font-family: 'Avenir';
}
.connect_item_mobile .carousel-indicators {
    bottom: -168px !important;
}
.connect_item_mobile .carousel-indicators li {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #D8D8D8;
}
.connect_item_mobile .carousel-indicators li.active {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #0055D4;
}
.connect_item_mobile .carousel-inner .justify-content-center {
    max-height: 300px;
    min-height: 300px;
}
/*.connect_item_mobile .carousel-inner .justify-content-center img {
  width: 78%;
}*/
.mobile_profile_page {
    background-color: #fff;
    position: fixed;
    z-index: 999;
    bottom: 0px;
    width: 100%;
    top: 0;
    overflow-y: scroll;
}
.mobile_profile_page .profile-location-add ul {
    list-style-type: none;
}
.mobile_profile_page .profile-location-add ul a i {
    color: #cc0605;
}
.mobile_profile_page .profile-location-add ul a span {
    display: inherit !important;
    color: #000;
    font-size: 16px;
    font-family: 'Avenir-Book';
    font-weight: 700;
    vertical-align: text-bottom;
}
.mobile_profile_page .mobile_profile_img img {
    width: 100%;
    object-fit: contain;
}
.mobile_profile_page .mobile_profile_img .arrow_left_icon {
    text-align: left;
    position: absolute;
    top: 22px;
    left: 22px;
    font-size: 21px;
}
.mobile_profile_page .mobile_profile_bg {
    background-color: #fff;
    padding: 12px 23px;
    margin-top: -22px;
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.mobile_profile_page .mobile_profile_bg {
    overflow-y: scroll;
    max-height: 500px;
    min-height: 500px;
}
.mobile_profile_bg .mobile_profile_location {
    color: #BEC2CE;
}
.mobile_profile_bg .mobile_profile_location h5 {
    margin-top: -1px;
    font-size: 12pt;
    font-family: 'Avenir-Medium';
}
.mobile_profile_bg .mobile_photographer h2 {
    font-family: 'Avenir 85';
    font-size: 30pt;
}
.mobile_profile_bg .mobile_profile_pic .mobile_photo_img {
    height: 34px;
    width: 34px;
    object-fit: cover;
}
.mobile_profile_bg .mobile_profile_pic .mobile_photo_img img {
    width: 100%;
    border-radius: 50%;
}
.mobile_profile_bg .mobile_profile_pic .mobile_photo_img h5 {
    margin-left: 12px;
    margin-top: 3px;
    font-family: 'Avenir-Book';
}
.mobile_profile_bg .mobile_para_work p {
    color: #000;
    font-size: 17pt;
    font-family: 'Avenir-Book';
}
.mobile_profile_bg .mobile_para_heading h5 {
    font-family: 'Avenir';
    font-weight: 900;
    color: #000;
}
.mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots img {
    border-radius: 13px;
    width: 96pt;
    height: 96pt;
    object-fit: cover;
}
.mobile_profile_bg .mobile_profile_button .mobile_calling_btn {
    background-color: #cc0605;
    border-radius: 8px;
}
.mobile_profile_bg .mobile_profile_button .mobile_calling_btn a {
    color: #fff;
    padding: 12px;
    display: block;
    font-size: 15pt;
    font-family: 'Avenir 85';
}
.mobile_profile_bg .mobile_profile_button {
    position: sticky;
    /* top: -18px; */
    bottom: 0px;
    width: 94%;
    left: 0;
    right: 0;
    margin: auto;
}
.profile-mobile-view .mobile-search-box .search-grapher-post .search-btn {
    margin-top: 13px;
}
.profile-mobile-view .mobile-search-box .search-grapher-post .search-btn a {
    border: 1px solid #ea0706;
    color: #ea0706;
    padding: 8px 19px;
}
.profile-mobile-view .mobile-search-box .search-grapher-post .search-btn a:hover {
    text-decoration: none;
}
.mobile_map .map-container .map>div {
    height: 716px !important;
}
.mobile_map .map-container .map {
    margin-top: 0;
}
.mobile_calling_btn .get_started {
    background-color: #cc0605;
    color: #fff;
    padding: 14px 66px;
    height: 0;
    text-transform: uppercase;
    border-radius: 8px;
    cursor: pointer;
}
.mobile_calling_btn .get_started:hover {
    text-decoration: none;
}
.connect_item_mobile .mobile_calling_btn .get_started span {
    margin-bottom: 17px;
    font-family: 'Avenir 85';
}

@media (min-width: 767px) and (max-width: 768px) {
    .mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots {
        width: 100%;
    }
    .mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots img {
        width: 100%;
        /* height: 96pt; */
        height: 100%;
        object-fit: cover;
    }
    .mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots:nth-child(1),
    .mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots:nth-child(2) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .login-types .logo-img {
        padding: 60px 0;
    }
    .mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots {
        width: 100%;
    }
    .mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots img {
        width: 100%;
        /* height: 96pt; */
        height: 100%;
        object-fit: cover;
    }
    .mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots:nth-child(1),
    .mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots:nth-child(2) {
        margin-right: 20px;
    }
}
@media (max-width: 480px) {
    .login-types .logo-img {
        padding: 130px 0;
    }
    section .company-detail-page {
        padding: 0;
    }
    .company-detail-page .profile-detail-box {
        padding: 11px 17px 11px 0;
    }
    .company-detail-page .profile-detail-box.company-name .justify-content-between {
        flex-direction: column-reverse;
    }
    .mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots:nth-child(1),
    .mobile_profile_bg .mobile_para_heading .mobile_asm_clicks_shots:nth-child(2) {
        margin-right: 20px;
    }
}

.toggle-sidebar {
    position: absolute;
    top: 0;
    right: 0;
}
.toggle-sidebar button {
    background: white;
    border: 0;
    box-shadow: none;
    color: black;
}
aside.aside-fold {
    width: 50px;
    min-width: unset;
    padding: 15px;
}
aside:not(.aside-fold) .aside-show {
    display: none;
}
aside.aside-fold i {
    cursor: pointer;
}
aside.aside-fold .aside-content, aside.aside-fold .search_results,
aside.aside-fold .toggle-sidebar {
    visibility: hidden;
}
@media (max-width: 768px) {
    aside.aside-fold .search_results {
        visibility: visible;
    }
    aside.aside-fold {
        width: 100%;
        max-height: 50px;
        padding: 15px;
    }
    .toggle-sidebar {
        top: unset;
        bottom: 0;
    }
    .company_lists {
        justify-content: center;
    }
    section .com-wrapper-contain .company-detail-bg {
        margin-right: 0;
    }
    #custom-sidebar-content-id {
        overflow-y: visible !important;
    }
}

.mobile-search-container {
    position: absolute;
    left: 0;
    bottom: 0;

    max-width: 100%;
    height: 100px;
    box-sizing: border-box;
    overflow-x: auto;
    background: transparent;
    z-index: 9999;
}

.mobile-search-items-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: nowrap;
    height: 100%;
    padding: 5px;
}

.mobile-search-items-container .aside-profile {
    position: relative;
    height: 100%;
    width: 200px;
    background: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 9px -4px rgba(0,0,0,.75);
    margin: 0 5px;
    min-width: 200px;
    max-width: 200px;
}

.mobile-search-items-container .aside-profile.active-profile {
    background: #007aff;
}

.mobile-search-items-container .aside-profile.active-profile  .profile-img-content h6 {
    color: #fff;
}

.mobile-search-items-container .aside-profile .profile-img img {
    height: 58px;
    width: 58px;
    object-fit: cover;
    border-radius: 50%;
}

.mobile-search-items-container .aside-profile .profile-img-content {
    padding-left: 10px;
    margin-top: 8px;
}

.mobile-search-items-container .aside-profile .profile-img-content .mb-1 {
    font-size: 13px;
    color: #000;
    font-weight: 400;
    font-family: Avenir-Book;
}

.mobile-search-items-container .aside-profile .profile-img-content .profile-img-content-h6 {
    color: #bec2ce;
    font-size: 13px;
    font-weight: 600;
    font-family: Avenir-Book;
}

.mobile-search-items-container .aside-profile .profile-img-content .profile-pro {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.language-selector {
    padding: 40px;
}

.language-selector select {
    padding: 5px;
}