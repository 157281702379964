.layout__search {
    margin: -1px 32px;
}

.layout__search .search-input div .autocomplete-dropdown-container {
    position: absolute;
}

.layout__search .search-input div .autocomplete-dropdown-container .suggestion-item {
    background-color: #fff!important;
    color: rgb(245, 45, 85)!important;
    font-size: 14px!important;
    padding: 16px 24px 10px!important;
}

.layout__search .search-input div .autocomplete-dropdown-container .suggestion-item--active {
    background-color:rgb(245, 45, 85)!important;
    color: #fff!important;
    font-size: 14px!important;
    padding: 16px 24px 10px!important;
}

.layout__search .search-input div .location-search-input  {
    font-family: "Avenir-Book";
    font-weight: 400;
    padding: 8px 33px;
}



.layout_message {
    color: blue;
}

.layout_message div #custom-sidebar-content-id div div {
    box-shadow: none!important ;
}

.layout__search div input {
    background: transparent;
    border: 1px solid #4f4f4f;
    border-radius: 4px;
    box-shadow: none;
    padding: 8px;
    color: #4f4f4f;
    font-size: 16px;
    font-family: 'Avenir 85';
    width: 105%;
    outline: 0;

}
