.home_container {
    display: flex;
    flex-direction: row;
}

.home_container--map {
    width: 100%;
}

.custom-marker {
    width: 25px;
    height: 31px;
    transform: translateX(-50%) translateY(-100%);
    z-index: 10;
}

.custom-marker.custom-marker-active, .custom-marker.custom-marker-active img.marker-pin {
    width: 38px;
    height: 47px;
}

.custom-marker img.marker-pin {
    width: 25px;
    height: 31px;
}

.custom-marker img.marker-pin:hover {
    cursor: pointer;
}

.custom-marker-location {
    width: 22px;
    height: 22px;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
}

.custom-marker-location img.marker-pin {
    width: 22px;
    height: 22px;
}

.info-window {
    position: relative;
    bottom: 265px;
    left: -110px;
    width: 220px;
    height: 190px;
    background-color: white;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    padding: 10px;
    font-size: 14px;
    z-index: 100
}

.info-window img {
    width: 100%;
    object-fit: cover;
}



.search_results {
    overflow: auto;
}

.search_results .aside-profile {
    margin: 15px 0;
}

.center-map {
    position: absolute;
    top: 70px;
    right: 0;
    background: white;
    z-index: 100;
    box-shadow: 0 2px 6px rgba(0,0,0,.3);
    margin: 10px;
    padding: 5px;
    border: none;
    border-radius: 5px;
}

.center-map:focus {
    outline: none;
}

.center-map:hover {
    background: rgb(236, 236, 236);
}

.center-map img {
    width: 30px;
    height: 30px;
}

aside.aside-photo-profile, aside.aside-company {
    min-width: 288px;
}
aside.aside-company.aside-fold {
    min-width: unset;
}

@media (max-width: 768px) {
    body {
        overflow: hidden;
    }
    .home_container {
        flex-direction: column;
        height: 100%;
    }
    .home_container--map, .home_container--sidebar {
        width: 100%;
    }
    .map-container {
        height: 100%;
    }
    aside {
        max-width: none;
        z-index: 105;
    }
    aside .form-group {
        margin-bottom: 0px;
    }
    aside.aside-fold {
        width: 50px;
    }
    aside .search-input input {
        padding: 0;
        color: #fff;
        font-size: 16px;
        font-family: 'Avenir 85';
        width: 100%;
        margin-bottom: 7px;
        outline: 0;
    }
    aside.aside-photo-profile {
        position: fixed;
        z-index: 1010;
        width: 100%;
        height: calc(100vh - 70px);
        max-width: none !important;
    }
    .aside-bg aside.aside-company {
        height: 210px;
        overflow: visible;
    }
    .map-container {
        position: absolute;
        bottom: 0;
        z-index: 10;
    }

    .search_results {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        padding: 5px;
    }

    .search_results .aside-profile {
        max-width: 200px;
        min-width: 200px;
        width: 200px;
        margin: 0 5px;
    }
    .profile-popup .modal-content .model-right-side {
        padding: 0;
    }
}

.carousel-inner {
    height: 100%;
}

.carousel-images {
    height: 50vh;
}

.carousel-item {
    padding: 0 15%;
    height: 100%;
    width: 100%;
}

.carousel-item img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

.carousel-image-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
   
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

